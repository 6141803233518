import { ConfigProvider, theme } from 'antd';
import BigNumber from 'bignumber.js';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import './styles/index.css';

BigNumber.config({ ROUNDING_MODE: BigNumber.ROUND_FLOOR });

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <BrowserRouter>
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
        token: {
          colorText: 'hsl(var(--text))',
          colorPrimary: 'hsl(var(--text))',
          colorBgMask: 'rgba(18, 18, 18, 0.8)',
          colorBgElevated: 'hsl(var(--card))',
          colorBgContainer: 'transparent',
          colorBorderSecondary: 'transparent',
          colorFillAlter: 'transparent',
        },
      }}
    >
      <App />
    </ConfigProvider>
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
