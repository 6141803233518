import { NeoContext } from 'App';
import { Modal, Popover } from 'antd';
import { FC, useContext, useState } from 'react';
import { ReactComponent as CloseIcon } from 'assets/images/close.svg';
import { ReactComponent as ExitIcon } from 'assets/images/exit.svg';
import { formatAddress } from 'utils/tools';
import { SUPPORTED_WALLETS_CONFIG } from './walletsConfig';

interface Props {
  className?: string;
}

export const NeoWalletConnect: FC<Props> = ({ className: _className }) => {
  const { connect, disconnect, address } = useContext(NeoContext);
  const [modal, contextHolder] = Modal.useModal();
  const [popoverOpen, setPopoverOpen] = useState(false);

  const showWallets = () => {
    const walletModal = modal.info({
      icon: null,
      footer: null,
      maskClosable: true,
      content: (
        <div className="w-full pb-[20px]">
          <div className="flex items-center justify-between">
            <div className="font-articulat text-[20px] font-semibold">Connect Wallet</div>
            <div
              className="cursor-pointer rounded-lg p-[8px] duration-200 hover:bg-text/20"
              onClick={() => walletModal.destroy()}
            >
              <CloseIcon className="h-[12px]" />
            </div>
          </div>
          {SUPPORTED_WALLETS_CONFIG.map(config => (
            <div
              key={config.name}
              className={`mt-[20px] flex cursor-pointer items-center gap-[10px] rounded-[10px] border-[1px] border-text/20 bg-card px-[30px] py-[20px] shadow-box-shadow-sm duration-200 hover:bg-text/20 ${
                config.name === 'Neon'
                  ? 'lg:hidden'
                  : config.name === 'OneGate'
                  ? 'hidden lg:flex'
                  : ''
              }`}
              onClick={() => {
                connect(config.name);
              }}
            >
              <img src={config.icon} className="h-[24px] w-[24px]" />
              <div className="font-articulat text-[17px] font-semibold">{config.name}</div>
            </div>
          ))}
        </div>
      ),
    });
  };

  if (!address) {
    return (
      <>
        {contextHolder}
        <button className={_className} onClick={() => showWallets()}>
          Connect to Neo N3
        </button>
      </>
    );
  }
  return (
    <Popover
      arrow={false}
      open={popoverOpen}
      onOpenChange={open => setPopoverOpen(open)}
      trigger="click"
      placement="bottom"
      overlayInnerStyle={{ padding: 0 }}
      content={
        <div
          className="flex h-[38px] w-[154px] cursor-pointer items-center justify-between rounded-[6px] border-[1px] border-text/10 px-[15px] py-[10px] text-text duration-200 hover:bg-text/10 sm:w-[98px] sm:text-[9px]"
          onClick={() => {
            disconnect();
            setPopoverOpen(false);
          }}
        >
          <span>Disconnect</span>
          <ExitIcon className="h-[11px] w-[11px] text-text sm:h-[9px] sm:w-[9px]" />
        </div>
      }
    >
      <button className="h-[37px] w-[154px] cursor-pointer rounded-[50px] border-[1px] border-white/20 bg-primary text-[14px] font-semibold text-white shadow-box-shadow duration-200 hover:bg-primary/75 sm:w-[98px] sm:text-[9px]">
        {formatAddress(address)}
      </button>
    </Popover>
  );
};
