export const formatAddress = (address: string): string => {
  return `${address.slice(0, 8)}...${address.slice(-5)}`;
};

export const post = async (urls: string | string[], body: any): Promise<any> => {
  const fetchData = {
    method: 'POST',
    body,
  };
  try {
    const response = await fetch(Array.isArray(urls) ? urls[0] : urls, fetchData);
    return response.json();
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const timeAgo = (timestamp: number): string => {
  const now = Date.now();
  const secondsAgo = Math.floor((now - timestamp) / 1000);

  if (secondsAgo < 60) {
    return `${secondsAgo} seconds ago`;
  } else if (secondsAgo < 3600) {
    const minutesAgo = Math.floor(secondsAgo / 60);
    return `${minutesAgo} minutes ago`;
  } else if (secondsAgo < 86400) {
    const hoursAgo = Math.floor(secondsAgo / 3600);
    return `${hoursAgo} hours ago`;
  } else {
    const daysAgo = Math.floor(secondsAgo / 86400);
    return `${daysAgo} days ago`;
  }
};
