import { MoonOutlined, SunOutlined } from '@ant-design/icons';
import { useWeb3ModalTheme } from '@web3modal/wagmi/react';
import { Dropdown } from 'antd';
import { FC, useEffect, useState } from 'react';

const ThemeToggle: FC = () => {
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  const { setThemeMode } = useWeb3ModalTheme();

  useEffect(() => {
    if (theme === 'dark') {
      document.documentElement.classList.add('dark');
      setThemeMode('dark');
    } else {
      document.documentElement.classList.remove('dark');
      setThemeMode('light');
    }
    localStorage.setItem('theme', theme);
  }, [theme, setThemeMode]);

  const toggleTheme = (theme: 'light' | 'dark') => {
    setTheme(theme);
  };

  return (
    <Dropdown
      placement="bottomLeft"
      menu={{
        items: [
          {
            key: '1',
            label: (
              <div className="flex items-center gap-[10px]" onClick={() => toggleTheme('light')}>
                <SunOutlined />
                <span>Light</span>
              </div>
            ),
          },
          {
            key: '2',
            label: (
              <div className="flex items-center gap-[10px]" onClick={() => toggleTheme('dark')}>
                <MoonOutlined />
                <span>Dark</span>
              </div>
            ),
          },
        ],
      }}
    >
      <div className="flex h-[30px] w-[30px] cursor-pointer items-center justify-center rounded-md border-[1px] border-text/20 hover:bg-text/20">
        {theme === 'dark' ? <MoonOutlined /> : <SunOutlined />}
      </div>
    </Dropdown>
    // <button
    //   onClick={toggleTheme}
    //   className="bg-gray-200 dark:bg-gray-800 rounded p-2 text-black dark:text-white"
    // >
    //   S{/* {theme === 'light' ? 'Switch to Dark Mode' : 'Switch to Light Mode'} */}
    // </button>
  );
};

export default ThemeToggle;
