import { useWeb3Modal } from '@web3modal/wagmi/react';
import { Popover } from 'antd';
import { FC, useState } from 'react';
import { useAccount, useDisconnect } from 'wagmi';
import { ReactComponent as ExitIcon } from 'assets/images/exit.svg';
import { formatAddress } from 'utils/tools';

interface Props {
  className?: string;
}

export const EvmWalletConnect: FC<Props> = ({ className: _className }) => {
  const { address } = useAccount();
  const { disconnect } = useDisconnect();
  const { open } = useWeb3Modal();
  const [popoverOpen, setPopoverOpen] = useState(false);

  if (!address) {
    return (
      <>
        <button className={_className} onClick={() => open()}>
          Connect to Neo X
        </button>
      </>
    );
  }
  return (
    <Popover
      arrow={false}
      open={popoverOpen}
      onOpenChange={open => setPopoverOpen(open)}
      trigger="click"
      placement="bottom"
      overlayInnerStyle={{ padding: 0 }}
      content={
        <div
          className="flex h-[38px] w-[154px] cursor-pointer items-center justify-between rounded-[6px] border-[1px] border-text/10 px-[15px] py-[10px] text-text duration-200 hover:bg-text/10 sm:w-[98px] sm:text-[9px]"
          onClick={() => {
            disconnect();
            setPopoverOpen(false);
          }}
        >
          <span>Disconnect</span>
          <ExitIcon className="h-[11px] w-[11px] text-text sm:h-[9px] sm:w-[9px]" />
        </div>
      }
    >
      <button className="h-[37px] w-[154px] cursor-pointer rounded-[50px] border-[1px] border-white/20 bg-secondary text-[14px] font-semibold text-white shadow-box-shadow duration-200 hover:bg-secondary/75 sm:w-[98px] sm:text-[9px]">
        {formatAddress(address)}
      </button>
    </Popover>
  );
};
