import { createPublicClient, defineChain } from 'viem';
import { createConfig, fallback, http } from 'wagmi';
import { walletConnect } from 'wagmi/connectors';
import { GAS_NEOX_DECIMALS } from './constants';

const NEOX_CHAIN_ID = process.env.REACT_APP_NEOX_CHAIN_ID as string;
const NEOX_CHAIN_NAME = process.env.REACT_APP_NEOX_CHAIN_NAME as string;
const NEOX_RPCs = JSON.parse(process.env.REACT_APP_NEOX_RPCs as string);
const NEOX_BLOCK_EXPLORER = process.env.REACT_APP_NEOX_BLOCK_EXPLORER as string;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const neox = defineChain({
  id: parseInt(NEOX_CHAIN_ID),
  name: NEOX_CHAIN_NAME,
  nativeCurrency: { name: 'Gas', symbol: 'GAS', decimals: GAS_NEOX_DECIMALS },
  rpcUrls: {
    default: { http: NEOX_RPCs },
  },
  blockExplorers: {
    default: { name: 'Neo X - Explorer', url: NEOX_BLOCK_EXPLORER },
  },
});

export const projectId = '949e1544d292d3e19763e9bfe652d1b1';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const walletConfig = createConfig({
  chains: [neox],
  connectors: [walletConnect({ projectId, showQrModal: false })],
  transports: {
    [neox.id]: fallback(NEOX_RPCs.map((v: string) => http(v))),
  },
});

export const publicClient = createPublicClient({
  chain: neox,
  transport: http(),
});
